export default class TagsModelMapper {
    map(serverModel) {
        const tags = [];
        const serverTags = serverModel.hasOwnProperty('tags') ? serverModel.tags : [];
        serverTags.forEach(serverTag => {
            const tag = {};
            serverTag.hasOwnProperty('id') ? tag.id = serverTag.id : console.warn("tag has no id field");
            serverTag.hasOwnProperty('geojson') ? tag.geojson = JSON.parse(serverTag.geojson) : console.warn("tag has no geojson filed");
            tags.push(tag)
        });
        return tags;
    }
}
