import MapperBase from "./mapper-base";

export default class RunningWaterMapper extends MapperBase {
    _requiredProperties = [
        'time_zone',
        'metadata.start_time_utm',
        'metadata.end_time_utm',
        'metadata.is_ongoing',
        'metadata.median_angle',
        'metadata.median_pressure_psi',
        'metadata.pivot_radius',
        'metadata.pivot_lat',
        'metadata.pivot_lon'
    ];

    map(insightData, translate) {
        this._verifyRequiredFields(insightData, this._requiredProperties, 'Missing required properties');

        this._translate = translate;

        const {
            time_zone: timezone,
            metadata: {
                median_angle: angle,
                is_ongoing: isOngoing,
                median_pressure_psi: pressure,
                water_applied_acre_inches: waterAppliedAcIn,
                pivot_radius: pivotRadiusMeters,
                pivot_lat: lat,
                pivot_lon: lon,
                start_time_utm: startTimeUtm,
                end_time_utm: endTimeUtm
            }
        } = insightData;

        const pivotCenterCoordinates = [lon, lat];
        const startTime = this._formatTimestamp(startTimeUtm * 1000, timezone);
        const endTime = this._formatTimestamp(endTimeUtm * 1000, timezone);

        return {
            pressureUnit: 'psi',
            startTime,
            endTime,
            isOngoing,
            pivotRadiusMeters,
            pivotCenterCoordinates,
            angle,
            waterApplied: this._convertAcreInchToGallon(waterAppliedAcIn),
            waterAppliedUnit: 'gallon',
            pressure
        };
    }

    _convertAcreInchToGallon(acreInch) {
        return acreInch * 27154;
    }
}
