const isObject = item => item && typeof item === 'object' && !Array.isArray(item);

/**
 * Deep merge 2 (or more) objects
 *
 * @param target
 * @param sources
 * @returns {*}
 */
export const mergeDeep = (target, ...sources) => {
    if (!sources.length) return target;
    const source = sources.shift();

    if (isObject(target) && isObject(source)) {
        for (const key in source) {
            if (isObject(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else {
                Object.assign(target, { [key]: source[key] });
            }
        }
    }

    return mergeDeep(target, ...sources);
}

/**
 * Convert object that contains keys with dots ({ 'ab.c.d': true }) into nested objects ({ ab: { c: { d: true }}})
 *
 * @param obj
 * @returns {{}}
 */
export const deepen = (obj) => {
    const result = {};

    // For each object path (property key) in the object
    for (const objectPath in obj) {
        // Split path into component parts
        const parts = objectPath.split('.');

        // Create sub-objects along path as needed
        let target = result;
        while (parts.length > 1) {
            const part = parts.shift();
            target = target[part] = target[part] || {};
        }

        // Set value at end of path
        target[parts[0]] = obj[objectPath]
    }

    return result;
}
