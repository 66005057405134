export function getInsightUid(textToSearch) {
    const extractInsightUidRegex = new RegExp('\\/i\\/((-?\\w)*)');
    const result = extractInsightUidRegex.exec(textToSearch);
    if (result == null) {
        return null
    }
    return result[1];
}

export function getUserUid(textToSearch) {
    const extractUserUidRegex = new RegExp('\\/u\\/(\\w*)');
    const result = extractUserUidRegex.exec(textToSearch);
    if (result == null) {
        return null
    }
    return result[1];
}
