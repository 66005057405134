import React from 'react';
import Loader from "react-loader-spinner";
import {ValleyBlue} from "../globals/Colors";
import {withNamespaces} from "react-i18next";

function AppLoader({ t }) {
    return <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh'}}>
        <Loader type="TailSpin" color={ValleyBlue} height={24} width={24}/>
        <div> {t('loading')} </div>
    </div>
}

export default withNamespaces()(AppLoader);
