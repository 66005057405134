import React from 'react';
import Logo from './assets/logo.svg';
import './InsightLandingPage.css';
import InsightViewer from './components/InsightViewer';
import InsightProvider from "./services/InsightProvider";
import AppLoader from "./components/Loader";
import toaster from "toasted-notes";
import {Toast} from "./components/Toast";
import {AnimateOnChange} from 'react-animation'
import AppTeaser from "./components/appTeaser/AppTeaser";
import {Cookies, withCookies} from 'react-cookie';
import {getInsightUid, getUserUid} from "./services/RegexExtractor";
import downloadAppLinkProvider from "./services/DownloadAppLinkProvider";
import copy from 'copy-to-clipboard';
import InsightDetailModelMapper, {InsightType} from "./models/InsightDetailModelMapper";
import BlurredFieldImage from './assets/blurred-field.png';
import {withI18n, withNamespaces} from "react-i18next";
import {momentInit} from "./services/MomentConfig";
import {numberFormat} from "./utils/number-format";
import {isMobile} from "react-device-detect";
import VIWebInsightURLProvider from "./services/vi-web-insight-url-provider";

const TOAST_AUTO_CLOSE_DURATION = 3 * 1000;

const ZOOM_PAN_OPTIONS = {
    viewportMargins: {
        left: 50,
        right: 50,
        top: 10,
        bottom: 110
    }
};

const VI_COOKIE_NAME = 'vi-app-teser';
const VI_COOKIE_CONTENT = '';

const AGSENSE_ANOMALY_INSIGHT_TYPES = [
    InsightType.PRESSURE_DROP,
    InsightType.RUNNING_WATER,
    InsightType.SPEED_SHIFT
];

class InsightLandingPage extends React.Component {

    constructor(props) {
        super(props);
        this.insightProvider = new InsightProvider(props.t);
        this.cookies = new Cookies();

        momentInit();

        this.state = {
            ready: false,
            imageViewerImage: null,
            insightDetail: null,
            tags: null,
            isAppTeaserVisible: false
        };
    }

    _redirectToViWeb() {
        if (!isMobile && this.insight?.insightDetail && !this._isAgSenseAnomalyInsight) {
            const insightUid = getInsightUid(window.location.pathname);
            window.location.replace(VIWebInsightURLProvider(insightUid));
        }
    }

    closeAppTeaser = () => {
        if (!this._isInsightTypeSupported()) {
            return;
        }
        this.setState({isAppTeaserVisible: false})
    };

    get _isAgSenseAnomalyInsight() {
        return AGSENSE_ANOMALY_INSIGHT_TYPES.includes(this.insight?.insightDetail?.type);
    };

    get _shouldShowAppTeaser() {
        if (!this._isInsightTypeSupported()) {
            return true;
        }

        if (this._isAgSenseAnomalyInsight) {
            return false;
        }

        return this.cookies.get(VI_COOKIE_NAME) == null;
    };

    async componentDidMount() {
        const insight = await this.insightProvider.provider(getInsightUid(window.location.pathname), getUserUid(window.location.pathname));
        this.insight = insight;

        if (this._shouldShowAppTeaser) {
            this.setState({isAppTeaserVisible: true});
            this._setCookieFor(1)
        }

        this.setState({
            imageViewerImage: insight?.imageViewerImage,
            insightDetail: insight?.insightDetail,
            tags: insight?.tags,
            ready: !!insight
        });
    }

    onShareClick = async () => {
        const { t } = this.props;
        const { insightDetail: { shareDate, fieldName, insightSubject, uid } } = this.state;

        const url = `${window.location.origin}/i/${uid}`;
        const shareParams = {
            date: shareDate,
            field: fieldName,
            subject: insightSubject,
            url
        };

        try {
            await window.navigator.share({
                title: insightSubject,
                text: t('insight.shareInsight', shareParams),
                url
            });
        } catch (e) {
            if (!e.toString().includes('AbortError')) {
                copy(t('insight.shareInsightWithUrl', shareParams));
                toaster.notify(() => <Toast toastText={t('copiedToClipboard')}/>, {
                    duration: TOAST_AUTO_CLOSE_DURATION
                });
            }
        }
    };

    _renderMockFieldImage() {
        return <div className='field-image-mock'>
            <img src={BlurredFieldImage} alt='Insights'/>
        </div>;
    }

    _getImageViewerImage() {
        const { t } = this.props;
        const { imageViewerImage } = this.state;
        const { pressureUnit } = this.insight.insightDetail?.pressureOptions || {}
        const pressureUnitLocalized = pressureUnit
            ? t(`pressureUnit.${pressureUnit}`)
            : null;
        return {
            ...imageViewerImage,
            type_desc: t(imageViewerImage.type_desc, { unit: pressureUnitLocalized })
        }
    }

    get _prepareIrrigationDataRows() {
        const {t: __} = this.props;
        const {affectedArea, imageTakenDate, description} = this.insight.insightDetail;
        return [
            {
                id: 'affected-area',
                label: __('insight.affectedArea'),
                value: affectedArea
            },
            {
                id: 'image-taken-date',
                label: __('insight.imageTaken'),
                value: imageTakenDate
            },
            {
                id: 'description',
                label: __('insight.description'),
                value: description
            }
        ];
    }

    get _preparePressureDataRows() {
        const {t: __} = this.props;
        const {
            insightDetail: {
                affectedArea,
                pressureOptions: {
                    startTime, endTime, isOngoing,
                    pressureUnit, normalPressure, dropPressure,
                    startAngle, endAngle
                }
            }
        } = this.insight;
        const pressureUnitLocalized = __(`pressureUnit.${pressureUnit}`);
        return [
            {
                id: 'start-time',
                label: __('insight.startTime'),
                value: startTime
            },
            {
                id: 'end-time',
                label: __('insight.endTime'),
                value: isOngoing ? __('insight.ongoingText') : endTime
            },
            affectedArea && {
                id: 'affected-area',
                label: __('insight.affectedArea'),
                value: affectedArea
            },
            {
                id: 'angles',
                label: __('insight.angles'),
                value: __('insight.anglesValue', { start: startAngle, end: endAngle })
            },
            {
                id: 'pressure-drop',
                label: __('insight.pressureDrop'),
                value: __('insight.pressureWithUnit', { value: dropPressure, unit: pressureUnitLocalized })
            },
            {
                id: 'normal-pressure',
                label: __('insight.normalPressure'),
                value: __('insight.pressureWithUnit', { value: normalPressure, unit: pressureUnitLocalized })
            }
        ];
    }

    get _prepareRunningWaterDataRows() {
        const {t: __, lng: locale} = this.props;
        const {
            insightDetail: {
                runningWaterOptions: {
                    startTime, endTime, isOngoing,
                    angle,
                    waterAppliedUnit, waterApplied,
                    pressure, pressureUnit
                }
            }
        } = this.insight;
        return [
            startTime && {
                id: 'start-time',
                label: __('insight.startTime'),
                value: startTime
            },
            (endTime || isOngoing) && {
                id: 'end-time',
                label: __('insight.endTime'),
                value: isOngoing ? __('insight.ongoingText') : endTime
            },
            {
                id: 'pivot-location',
                label: __('insight.pivotLocation'),
                value: __('insight.pivotLocationValue', { angle: numberFormat(angle) })
            },
            waterApplied && {
                id: 'water-applied',
                label: __('insight.waterApplied'),
                value: __(
                    'insight.waterAppliedValue',
                    {
                        value: numberFormat(waterApplied, 0, locale),
                        unit: __(`wateringUnit.${waterAppliedUnit}`)
                    }
                )
            },
            pressure && {
                id: 'pressure-value',
                label: __('insight.pressureValue'),
                value: __('insight.pressureValueText', { value: pressure, unit: __(`pressureUnit.${pressureUnit}`) })
            }
        ];
    }

    get _prepareSpeedShiftDataRows() {
        const {t: __} = this.props;
        const {
            insightDetail: {
                speedShiftOptions: {
                    percentInWaterApplied,
                    configuredRuntimeMinutesSum,
                    measuredRuntimeMinutesSum,
                    fasterThanConfig
                }
            }
        } = this.insight;
        return [
            configuredRuntimeMinutesSum && {
                id: 'configured-runtime',
                label: __('insight.configuredRuntime'),
                value: __('insight.hoursAndMinutesPerCycle', this._getRuntimeByMinutes(configuredRuntimeMinutesSum))
            },
            measuredRuntimeMinutesSum && {
                id: 'measured-runtime',
                label: __('insight.measuredRuntime'),
                value: __('insight.hoursAndMinutesPerCycle', this._getRuntimeByMinutes(measuredRuntimeMinutesSum))
            },
            percentInWaterApplied && {
                id: 'water-applied',
                label: __('insight.diffWaterApplied'),
                value: __(
                    `insight.diffWaterApplied${fasterThanConfig ? 'Less' : 'More'}Value`,
                    { value: numberFormat(percentInWaterApplied) }
                )
            }
        ];
    }

    _getRuntimeByMinutes(minutesSum) {
        const {t: __} = this.props;
        const hours = Math.floor(minutesSum / 60);
        const minutes = minutesSum % 60;
        const hoursLocalized = hours ? __('insight.hours', { hours }) : '';
        const minutesLocalized = minutes ? __('insight.minutes', { minutes }) : '';
        return {
            hours: hoursLocalized,
            minutes: minutesLocalized
        };
    }

    get _insightDataRows() {
        const {insightDetail: {type}} = this.insight;
        switch (type) {
            case InsightType.PRESSURE_DROP:
                return this._preparePressureDataRows;
            case InsightType.RUNNING_WATER:
                return this._prepareRunningWaterDataRows;
            case InsightType.SPEED_SHIFT:
                return this._prepareSpeedShiftDataRows;
            default:
                return this._prepareIrrigationDataRows;
        }
    }

    get _shouldShowLegend() {
        return ![InsightType.RUNNING_WATER, InsightType.SPEED_SHIFT].includes(this.insight.insightDetail.type);
    }

    _renderInsightViewer() {
        this._redirectToViWeb();

        const { i18n } = this.props;
        return <div className='insight-viewer'>
            { !this._isInsightTypeSupported()
              ? this._renderMockFieldImage()
              : <InsightViewer is-share-button-enabled={true}
                               is-share-text-shown={false}
                               publicAssetPath="/static"
                               imageViewerImage={this._getImageViewerImage()}
                               insightDetail={this.state.insightDetail} tags={this.state.tags}
                               zoomPanOptions={ZOOM_PAN_OPTIONS} onShareCallback={this.onShareClick}
                               should-show-tags={true}
                               insightDataRows={this._insightDataRows.filter(Boolean)}
                               language={i18n.language}
                               should-show-legend={this._shouldShowLegend} /> }
        </div>
    }

    _renderFooter() {
        if (this._isAgSenseAnomalyInsight) {
            return null;
        }

        this._showFooterWithDelay(2000);

        return <footer className="vi-footer" id='vi-footer-id'>
            <div className="get-app-text">
                {this.props.t('getValleyApp')}
            </div>
            <div className="get-vi-app-button" onClick={this._redirectToAppStore}>
                <div className="footer-text blue1-text">{this.props.t('download')}</div>
            </div>
        </footer>;
    }

    render() {
        if (!this.state.ready) {
            return <AppLoader/>
        }
        return (
            <div className="landing-page-container">
                {this.state.isAppTeaserVisible ?
                    <div className='app-teaser-background' onClick={this.closeAppTeaser}/> : null}
                {this.state.isAppTeaserVisible ? this._getAppTeaser() : null}

                <header className="vi-header">
                    <Logo />
                </header>

                {this._renderInsightViewer()}
                {this._renderFooter()}
            </div>
        )
    }

    _showFooterWithDelay(ms) {
        setTimeout(() => {
            document.getElementById('vi-footer-id').style.visibility = "visible"
        }, ms)
    }

    _isInsightTypeSupported() {
        return InsightDetailModelMapper.isInsightTypeSupported(this.insight?.insightDetail?.type);
    }

    _getAppTeaser() {
        return <div style={{height: 0}}>
            <AnimateOnChange>{this.state.isAppTeaserVisible ?
                <AppTeaser onContinueClick={this.closeAppTeaser}
                           isContinueEnabled={this._isInsightTypeSupported()}
                           onDownloadAppClick={this._redirectToAppStore}/> : null}</AnimateOnChange>
        </div>
    }

    _setCookieFor(days) {
        let expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + days);

        this.cookies.set(VI_COOKIE_NAME, VI_COOKIE_CONTENT, {expires: expireDate});
    }

    _redirectToAppStore() {
        const insightUid = getInsightUid(window.location.pathname);
        const url = downloadAppLinkProvider(insightUid);
        console.info('Download url', url);
        window.location.href = url;
        // window.open(downloadAppLinkProvider(), "_blank");
    }

}

export default withI18n()(
    withNamespaces()(
        withCookies(InsightLandingPage)
    )
);
