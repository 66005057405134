import i18n from "i18next";
import {reactI18nextModule} from "react-i18next";
import detector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import {deepen, mergeDeep} from "../utils/object-utils";

const supportedLanguages = ['en_US', 'pt_BR'];

const getRemoteLocaleUrl = locale => (process.env.REACT_APP_I18N_REMOTE_URL + '?t=' + Date.now()) // Add a query string to avoid browser-level caching
    .replace('%locale%', locale);

const loadLocaleStrings = language => Promise.all([
    import(`../i18n/${language}.json`).then(module => module.default),
    fetch(getRemoteLocaleUrl(language))
        .then(res => res.json())
])
    // Convert object that contains keys with dots into nested objects, and deep-merge the results
    .then(([local, remote]) => mergeDeep(deepen(local), deepen(remote)));

i18n
    .use(detector)
    .use(reactI18nextModule) // passes i18n down to react-i18next
    .use(resourcesToBackend((language, _, callback) =>
        loadLocaleStrings(language)
            .then((resources) => callback(null, resources))
            .catch(e => callback(e, null))
    ))
    .init({
        supportedLngs: supportedLanguages,
        fallbackLng: "en_US",
        interpolation: {
            escapeValue: false // react already safes from xss
        },
        react: {
            wait: true
        }
    });

export default i18n;
