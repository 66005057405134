import MapperBase from "./mapper-base";

export default class PressureDropMapper extends MapperBase {
    _requiredProperties = [
        'time_zone',
        'metadata.psi_range_for_plot.0',
        'metadata.psi_range_for_plot.1',
        'metadata.start_angle',
        'metadata.end_angle',
        'metadata.start_time_utm',
        'metadata.end_time_utm',
        'metadata.psi_vals_for_plot.angle_bin_start',
        'metadata.psi_vals_for_plot.col_pressure',
        'metadata.affected_acres',
        'metadata.field_polygon',
        'metadata.pivot_radius',
        'metadata.pivot_lat',
        'metadata.pivot_lon'
    ];

    map(insightData, translate) {
        this._verifyRequiredFields(insightData, this._requiredProperties, 'Missing required properties');

        this._translate = translate;

        const {
            pressure_unit: pressureUnit = 'psi',
            psi_range_for_plot: [minPressure, maxPressure],
            start_angle: startAngle,
            end_angle: endAngle,
            is_ongoing: isOngoing,
            affected_acres: affectedAcres,
            normal_pressure: normalPressure,
            drop_pressure: dropPressure,
            field_polygon: fieldPolygonString,
            pivot_radius: pivotRadiusMeters,
            pivot_lat: lat,
            pivot_lon: lon,
            start_time_utm: startTimeUtm,
            end_time_utm: endTimeUtm,
            psi_vals_for_plot: psiValuesForPlot
        } = insightData.metadata;
        const { time_zone: timezone } = insightData;

        const fieldPolygon = JSON.parse(fieldPolygonString);
        const pivotCenterCoordinates = [lon, lat];
        const startTime = this._formatTimestamp(startTimeUtm * 1000, timezone);
        const endTime = this._formatTimestamp(endTimeUtm * 1000, timezone);

        const pressureByAngleMap = this._formatPressureByAnglesAndPressureArrays(
            psiValuesForPlot.angle_bin_start,
            psiValuesForPlot.col_pressure
        );

        return {
            fieldPolygon: fieldPolygon.coordinates[0],
            pressureUnit,
            minPressure,
            maxPressure,
            startAngle,
            endAngle,
            startTime,
            endTime,
            isOngoing,
            pivotRadiusMeters,
            pivotCenterCoordinates,
            pressureByAngleMap,
            affectedAcres,
            normalPressure,
            dropPressure
        };
    }

    _formatPressureByAnglesAndPressureArrays(angles, values) {
        return Object.entries(angles)
            .reduce((map, [index, angle]) => {
                    let value = values[index];
                    if (value === undefined || value === null) {
                        console.warn(`Missing pressure value for angle ${angle}`);
                        return map;
                    }
                    value = +value;
                    value = value > 0 ? value : 0;
                    angle = Math.round(+angle);
                    map.set(angle, value);
                    return map;
                },
                new Map()
            );
    }
}
