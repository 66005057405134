import {isIOS, isAndroid} from "react-device-detect";

const downloadAppLinkProvider = insightUid => {
    const url = isIOS
      ? process.env.REACT_APP_DOWNLOAD_IOS_APP_LINK
      : isAndroid ?
        process.env.REACT_APP_DOWNLOAD_ANDROID_APP_LINK
        : '';
    return url.replace('%insight_uid%', insightUid);
};

export default downloadAppLinkProvider;
