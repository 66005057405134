import getMainImage from "../services/GetMainImage";
import {formatTimestamp} from "./timestamp";
import PressureDropMapper from "./pressure-drop-mapper";
import RunningWaterMapper from "./running-water-mapper";
import SpeedShiftMapper from "./speed-shift-mapper";
import InsightModelMapper from "./InsightModelMapper";

export const InsightType = { // enum
    DEFAULT: 'default',
    IRRIGATION: 'irrigation',
    PRESSURE_DROP: 'pressure_drop',
    RUNNING_WATER: 'running_water',
    SPEED_SHIFT: 'speed_mismatch'
}

export default class InsightDetailModelMapper {
    _translate;
    pressureDropMapper = new PressureDropMapper();
    runningWaterMapper = new RunningWaterMapper();
    speedShiftMapper = new SpeedShiftMapper();
    insightModelMapper = new InsightModelMapper();

    map(serverModel, translateCallback) {
        this._translate = translateCallback;

        const { time_zone, type } = serverModel;
        const mainImage = this._isAgSenseInsight(type)
            ? this.insightModelMapper.mapMetadataCoverImage(serverModel)
            : getMainImage(serverModel);
        const insightDetail = {
            type,
            isAgSenseInsight: this._isAgSenseInsight(type)
        };

        if (InsightDetailModelMapper.isInsightTypeSupported(type)) {
            serverModel.hasOwnProperty('uid') ? insightDetail.uid = serverModel.uid : console.warn('`uid` field does not exist on server model');
            serverModel.hasOwnProperty('farm_name') ? insightDetail.farmName = serverModel.farm_name : console.warn('`farm_name` field does not exist on server model');
            serverModel.hasOwnProperty('field_name') ? insightDetail.fieldName = serverModel.field_name : console.warn('`field_name` field does not exist on server model');

            serverModel.hasOwnProperty('subject')
                ? insightDetail.insightSubject = this._getTranslatedDynamicText(serverModel.i18n_subject, serverModel.subject)
                : console.warn('`subject` field does not exist on server model');

            serverModel.hasOwnProperty('description')
                ? insightDetail.description = this._getTranslatedDynamicText(serverModel.i18n_description, serverModel.description)
                : console.warn('`description` field does not exist on server model');

            switch (type) {
                case InsightType.PRESSURE_DROP:
                    const pressureOptions = this.pressureDropMapper.map(serverModel, this._translate);
                    Object.assign(
                        insightDetail,
                        {
                            pressureOptions,
                            affectedArea: this._getAffectedArea(pressureOptions.affectedAcres, {token: 'acres'})
                        }
                    );
                    insightDetail.shareDate = this._getImageTakenDate(
                        serverModel.metadata.start_time_utm, time_zone, 'date.short'
                    );
                    break;
                case InsightType.RUNNING_WATER:
                    const runningWaterOptions = this.runningWaterMapper.map(serverModel, this._translate);
                    insightDetail.runningWaterOptions = runningWaterOptions;
                    insightDetail.shareDate = this._getImageTakenDate(
                        serverModel.metadata.start_time_utm, time_zone, 'date.short'
                    );
                    break;
                case InsightType.SPEED_SHIFT:
                    insightDetail.speedShiftOptions = this.speedShiftMapper.map(serverModel.metadata, serverModel, this._translate);
                    insightDetail.shareDate = this._getImageTakenDate(
                        serverModel.ts_published._seconds, time_zone, 'date.short'
                    );
                    break;
                default:
                    if (mainImage?.hasOwnProperty('date')) {
                        insightDetail.imageTakenDate = this._getImageTakenDate(mainImage.date._seconds, time_zone);
                        insightDetail.shareDate = this._getImageTakenDate(mainImage.date._seconds, time_zone, 'date.short');
                    } else {
                        console.warn('`date` field does not exist on server model');
                    }

                    serverModel.hasOwnProperty('transformed_tags_area')
                        ? insightDetail.affectedArea = this._getAffectedArea(serverModel.transformed_tags_area, serverModel.area_unit)
                        : console.warn('`transformed_tags_area` field does not exist on server model');
                    break;
            }
        }

        return insightDetail;
    }

    static isInsightTypeSupported(type) {
        return !type || Object.values(InsightType).includes(type);
    }

    _getImageTakenDate(seconds, timezone, formatKey = 'date.fullWithTz') {
        return formatTimestamp(seconds, timezone, this._translate(formatKey));
    }

    _getTranslatedDynamicText(translatedObj, defaultText) {
        return translatedObj ? this._translate(
            translatedObj.token,
            {
                ...(translatedObj.params || {}),
                defaultValue: defaultText
            }
        ) : defaultText;
    }

    _getAffectedArea(affectedArea, areaUnit) {
        const area = affectedArea.toFixed(2);
        const unit = this._translate(areaUnit.token);
        const params = { area, unit };
        return this._translate(
            'insight.affectedAreaLabel',
            params
        );
    }

    _isAgSenseInsight(type) {
        return [
            InsightType.PRESSURE_DROP,
            InsightType.RUNNING_WATER,
            InsightType.SPEED_SHIFT
        ].includes(type);
    }

}
