import React from "react";
import './Toast.css'

export const Toast = (props) => {
    return <div className='copied-to-clipboard-toast'>
        <div className='toast-text'>
            {props.toastText}
        </div>
    </div>
}
