import {formatTimestampMillisecond} from "./timestamp";
import {isNil} from "../utils/is-nil";

export default class MapperBase {
    _translate;

    _getPropertyFromData(obj, property) {
        let parts = property.split('.');
        let current = obj;
        for (let i = 0; i < parts.length; i++) {
            if (isNil(current[parts[i]])) {
                return null;
            } else {
                current = current[parts[i]];
            }
        }
        return current;
    }

    _verifyRequiredFields(obj, requiredFields, errorMessage) {
        const missingProperties = requiredFields.filter(field => isNil(this._getPropertyFromData(obj, field)));
        if (missingProperties.length > 0) {
            console.error(errorMessage, missingProperties);
        }
    }

    _formatTimestamp(ms, timezone) {
        return formatTimestampMillisecond(ms, timezone, this._translate('date.fullWithTz'))
    }
}
