
function getMainImage(serverModel) {
    if (!serverModel.hasOwnProperty('images') || !serverModel.images.length > 0) {
        console.warn("No images on insight");
        return null;
    }
    const images = serverModel.images;
    return images.find(image => image.is_main_image);
}

export default getMainImage
