export const numberFormat = (number, decimals = 0, locale) => {
  let options = {};
  if (typeof decimals === 'number') {
    options.minimumFractionDigits = decimals;
    options.maximumFractionDigits = decimals;
  } else {
    options = decimals;
  }
  if (locale) {
    locale = locale.replace('_', '-');
  }
  const format = new Intl.NumberFormat(locale, options);
  return format.format(number);
}
