import MapperBase from "./mapper-base";

export default class SpeedShiftMapper extends MapperBase {
    _requiredMetadataProperties = [
        'percent_change_in_water_applied',
        'configured_runtime_minutes',
        'faster_than_config',
        'measured_runtime_minutes'
    ];

    map(serverModel, fieldData, translate) {
        this._verifyRequiredFields(serverModel, this._requiredMetadataProperties, 'Missing required metadata properties');

        this._translate = translate;

        const {
            percent_change_in_water_applied: percentInWaterApplied,
            measured_runtime_minutes: measuredRuntimeMinutesSum,
            configured_runtime_minutes: configuredRuntimeMinutesSum,
            faster_than_config: fasterThanConfig
        } = serverModel;

        return {
            percentInWaterApplied,
            measuredRuntimeMinutesSum,
            configuredRuntimeMinutesSum,
            fasterThanConfig
        };
    }
}
