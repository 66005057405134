import moment from "moment";

const GMT_ABBR = 'GMT';
const ONLY_EN_LETTERS_REGEX = /^[A-Za-z]*$/;

export const momentInit = () => {
  _overrideMomentFnZoneName();
};

// moment 'z' formatting token will not always show the abbreviated time zone name
// in those cases, we'll show GMT + utc offset
const _overrideMomentFnZoneName = () => {
  moment.fn.zoneName = function() {
    const zoneAbbr = this.zoneAbbr();
    const hasTzAbbr = new RegExp(ONLY_EN_LETTERS_REGEX).test(zoneAbbr);
    if (hasTzAbbr) {
      return zoneAbbr;
    }
    if (this.utcOffset() !== 0) {
      const utcOffsetHours = this.utcOffset() / 60;
      return `${GMT_ABBR}${utcOffsetHours < 0 ? '' : '+'}${utcOffsetHours}`;
    }
    return GMT_ABBR;
  };
};
