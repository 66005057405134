import getMainImage from "../services/GetMainImage";
import {InsightType} from "./InsightDetailModelMapper";
import MapperBase from "./mapper-base";

const TYPE_TO_DISPLAY_NAME = {
    rgb: 'imageLayerData.rgb.name',
    ndvi: 'imageLayerData.ndvi.name',
    thermal: 'imageLayerData.thermal.name',
    pressure: 'imageLayerData.pressure.name'
}

export default class InsightModelMapper extends MapperBase {

    map(serverModel, insightDetail) {
        const mainImage = getMainImage(serverModel);
        return mainImage ? this._mapImages(mainImage, insightDetail) : null;
    }

    _mapImages(imagesServerModel, { type: insightType }) {
        const images = {};
        imagesServerModel.hasOwnProperty('image_id') ? images.image_id = imagesServerModel.image_id : console.warn('image_id filed does not exist on server model');

        if (insightType === InsightType.PRESSURE_DROP) {
            images.type = 'pressure';
            images.type_desc = this._mapTypeToDisplayName('pressure');
        } else {
            if (imagesServerModel.hasOwnProperty('type')) {
                images.type = imagesServerModel.type;
                images.type_desc = this._mapTypeToDisplayName(imagesServerModel.type);
            } else {
                console.warn('type filed does not exist on server model');
            }
        }

        imagesServerModel.hasOwnProperty('bounds') ? images.bounds = imagesServerModel.bounds : console.warn('bounds filed does not exist on server model');

        if (imagesServerModel.hasOwnProperty('preview')) {
            images.preview = [];
            imagesServerModel.preview.forEach(preview => {
                images.preview.push(this._mapImageLevels(preview))
            })
        }
        return images;
    }

    _mapImageLevels(imageLevelServerModel) {
        const imageLevel = {};
        imageLevelServerModel.hasOwnProperty('url') ? imageLevel.url = imageLevelServerModel.url : console.warn('url filed does not exist on server model');
        imageLevelServerModel.hasOwnProperty('width') ? imageLevel.width = imageLevelServerModel.width : console.warn('width filed does not exist on server model');
        imageLevelServerModel.hasOwnProperty('height') ? imageLevel.height = imageLevelServerModel.height : console.warn('height filed does not exist on server model');
        if (imageLevelServerModel.hasOwnProperty('colormap')) {
            imageLevel.colormap = imageLevelServerModel.colormap;
        }
        return imageLevel;
    }

    _mapTypeToDisplayName(type) {
        if (!TYPE_TO_DISPLAY_NAME.hasOwnProperty(type)) {
            console.warn('type filed does not exist on server model');
        }
        return TYPE_TO_DISPLAY_NAME[type];
    }

    mapMetadataCoverImage(serverModel) {
        this._verifyRequiredFields(
            serverModel,
            [
                'type',
                'metadata.cover_image.0.bounds',
                'metadata.cover_image.0.width',
                'metadata.cover_image.0.height',
                'metadata.cover_image.0.url'
            ],
            `Missing cover image fields (metadata)`
        );
        const { type, metadata } = serverModel;
        const { bounds, width, height, url } = metadata?.cover_image?.[0];
        return {
            id: url,
            bounds,
            preview: [
                {
                    url,
                    width,
                    height
                }
            ],
            type: type === InsightType.PRESSURE_DROP ? 'pressure' : type
        };
    }
}
